import { Spin } from "antd"
import { t } from "i18next"
import { NextPage } from "next"
import Head from "next/head"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"

import {
  SelectInput,
  Pagination as SharedPagination,
  removeBlankAttributes,
  scrollToElementTop,
  theme,
} from "@project/shared"
import moment from "moment"
import { useRouter } from "next/router"
import ReactHtmlParser from "react-html-parser"
import { AccessErrorPage } from "../../components"
import CalendarIcon from "../../public/assets/icons/CalendarIcon"
import { fetchChildProgramRecord } from "../../services"
import { AuthContext } from "../../utils"
import { Divisor, FormTitle } from "../../utils/shared-style"
import withPrivateRoute from "../../withPrivateRoute"
const DataContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 41px;
  padding: 11px 30px 27px 30px;
  table th {
    word-break: break-all;
    white-space: pre-wrap;
  }

  .title {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #ff8200;
  }
`
const SelectFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  .select-class {
    @media (max-width: 767px) {
      width: 250px;
    }
  }
  .ant-select-clear {
    transform: scale(1.5);
    margin-right: 6px;
    opacity: 1;
    color: #d2d1d1;
  }

  .ant-select-clear:hover {
    color: #555755;
  }
`

const ContentWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
  white-space: pre-wrap;
  padding-bottom: 15px;

  p span {
    word-break: break-all;
  }
  & img {
    width: 100%;
    max-width: 300px;
    object-fit: contain;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;

  & > img {
    width: 100%;
    max-width: 1200px;
    max-height: 20rem;
    object-fit: cover;
  }
`
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`
export const ItemWrapper = styled.div`
  border: 1px #d2d1d1 solid;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 18px;
`

export const DateWrapper = styled.div`
  padding: 10px 2px;
  span.date {
    padding: 0px 5px;
    vertical-align: middle;
    font-weight: 400;
    line-height: 24px;
  }
  span.calendar {
    vertical-align: sub;
  }
`

const TitleWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29.96px;
  display: flex;
  padding-bottom: 5px;
  word-break: break-all;
  white-space: pre-wrap;
  cursor: pointer;
  color: #0782c8;
  @media (max-width: 767px) {
    white-space: pre-wrap;
  }
`
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  @media (max-width: ${theme.breakpoints.md}) {
    padding-bottom: 0;
    &.footer-pagination-wrapper > div {
      margin: 0 0;
    }
  }
`
const PdfWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  border: 1px solid #d2d1d1;
  border-radius: 5px;
  padding: 4px 12px;
  width: max-content;
  @media (max-width: 767px) {
    width: auto;
    & .image-title {
      word-break: break-all;
    }
  }
`

export const monthOptions = [
  {
    label: t("Jan"),
    value: "1",
  },
  {
    label: t("Feb"),
    value: "2",
  },
  {
    label: t("Mar"),
    value: "3",
  },
  {
    label: t("Apr"),
    value: "4",
  },
  {
    label: t("May"),
    value: "5",
  },
  {
    label: t("Jun"),
    value: "6",
  },
  {
    label: t("Jul"),
    value: "7",
  },
  {
    label: t("Aug"),
    value: "8",
  },
  {
    label: t("Sep"),
    value: "9",
  },
  {
    label: t("Oct"),
    value: "10",
  },
  {
    label: t("Nov"),
    value: "11",
  },
  {
    label: t("Dec"),
    value: "12",
  },
]

export const ProgramRecordPageComponent: NextPage = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { childInformation } = useContext(AuthContext)
  const { page, month } = router?.query as any
  const [queryParam, setQueryParam] = useState({
    category_id: "",
    month: month || "",
    page: +page || 1,
    pageSize: 20,
  })
  const divRef = useRef<HTMLDivElement>(null)
  const updateSearchParams = (key: string, value: any) => {
    router.push("/program-record", {
      query: removeBlankAttributes({
        ...queryParam,
        [key]: value,
      }),
    })
  }
  const handlePaginationChange = (page: number) => {
    setQueryParam({
      ...queryParam,
      page,
    })
    updateSearchParams("page", page)
  }

  const {
    isLoading: activityLoading,
    isFetching: activityFetching,
    data: activityData,
  } = useQuery(["fetch-activity-data", queryParam], fetchChildProgramRecord, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    select: (response) => {
      return {
        data: response?.data?.map((data) => {
          return {
            key: data.id,
            id: data.id,
            date: data.date,
            article_content_1: data?.article_content_1 || "",
            article_content_img_1: data?.article_content_img_1 || "",
            article_content_2: data?.article_content_2 || "",
            article_content_img_2: data?.article_content_img_2 || "",
            article_title: data?.article_title || "",
            image1_filename: data?.image1_filename || "",
            image2_filename: data?.image2_filename || "",
          }
        }),
        count: response?.count,
      }
    },
    onSuccess: () => {
      scrollToElementTop(divRef)
    },
  })

  const detailPageHandler = (id) => {
    router.push(`/program-record/details/${id}`)
  }

  return (
    <>
      <Head>
        <title>{t("Program record")}</title>
      </Head>

      <DataContainer>
        <FormTitle>
          <img src={"/assets/icons/title_icon.svg"} />
          <span style={{ marginLeft: "7px" }}>{t("Program record")}</span>
        </FormTitle>
        <Divisor />
        {childInformation?.my_page_setting?.activity_record ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <SelectFieldWrapper>
                <SelectInput
                  width={200}
                  height={40}
                  className={"select-class"}
                  placeholder={t("Monthly archieve")}
                  options={monthOptions}
                  value={queryParam.month || null}
                  clear
                  onChange={(values) => {
                    setQueryParam((prev) => {
                      return {
                        ...prev,
                        month: values,
                        page: 1,
                      }
                    })
                    updateSearchParams("month", values)
                  }}
                />
              </SelectFieldWrapper>
              {activityFetching ||
              activityLoading ? null : activityData?.count < 1 ? (
                <></>
              ) : (
                <PaginationWrapper>
                  <SharedPagination
                    total={activityData?.count}
                    current={queryParam.page}
                    pageSize={queryParam.pageSize}
                    onChange={handlePaginationChange}
                  />
                </PaginationWrapper>
              )}
            </div>
            {activityFetching || activityLoading ? (
              <SpinWrapper>
                <Spin />
              </SpinWrapper>
            ) : null}
            {activityFetching || activityLoading ? null : activityData?.count <
              1 ? (
              <span style={{ textAlign: "center", display: "block" }}>
                {t("No Data Found")}
              </span>
            ) : (
              <></>
            )}
            <div ref={divRef}>
              {activityData &&
                activityData?.data?.map((singleData) => {
                  return (
                    <>
                      <ItemWrapper>
                        <TitleWrapper
                          onClick={() => detailPageHandler(singleData.id)}
                        >
                          {singleData?.article_title}
                        </TitleWrapper>
                        <DateWrapper>
                          <span className={"calendar"}>
                            <CalendarIcon />
                          </span>
                          <span className={"date"}>
                            {moment(singleData?.date).isValid() &&
                              moment(singleData?.date).format("YYYY年MM月DD日")}
                          </span>
                        </DateWrapper>
                        <Divisor />
                        {singleData.article_content_img_1 !== "" &&
                          (singleData?.article_content_img_1.startsWith(
                            "data:image"
                          ) ? (
                            <ImageWrapper>
                              <img src={singleData.article_content_img_1} />
                            </ImageWrapper>
                          ) : (
                            <PdfWrapper>
                              <img
                                src={"/assets/icons/daily-report.svg"}
                                alt={"PDF"}
                                width={"30px"}
                              />
                              <div>
                                <a
                                  target={"_blank"}
                                  href={singleData?.article_content_img_1}
                                  rel={"noopener noreferrer"}
                                  download={singleData?.image1_filename}
                                >
                                  <span className={"image-title"}>
                                    {singleData?.image1_filename}
                                  </span>
                                </a>
                              </div>
                            </PdfWrapper>
                          ))}

                        <ContentWrapper>
                          {ReactHtmlParser(singleData.article_content_1)}
                        </ContentWrapper>
                      </ItemWrapper>{" "}
                    </>
                  )
                })}
            </div>
            {activityFetching ||
            activityLoading ||
            activityData?.count < 1 ? null : (
              <PaginationWrapper className={"footer-pagination-wrapper"}>
                <SharedPagination
                  total={activityData?.count}
                  current={queryParam.page}
                  pageSize={queryParam.pageSize}
                  onChange={handlePaginationChange}
                />
              </PaginationWrapper>
            )}
          </>
        ) : (
          <AccessErrorPage
            title={t("Thank you for your time")}
            subTitle={t("The program record is not currently publish")}
          />
        )}
      </DataContainer>
    </>
  )
}

const ProgramRecordPage: NextPage = () => {
  const { t } = useTranslation()
  const { childInformation } = useContext(AuthContext)
  if (childInformation && !childInformation?.my_page_setting?.activity_record) {
    return <AccessErrorPage title={t("Thank you for your time")} />
  }
  return <ProgramRecordPageComponent />
}
export default withPrivateRoute(ProgramRecordPage, {
  title: "マイページトップ",
  route: `program-record`,
})
