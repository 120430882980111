import { theme } from "@project/shared"
import { Layout as AntLayout } from "antd"
import styled from "styled-components"

export const Layout = styled(AntLayout)`
  &.dashboard {
    min-height: 100vh;
  }
  .sidebar-header-logo {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
  }
  .dashboard-content {
    padding: 60px;
    padding-top: 0;
    @media print {
      padding: 0px !important;
      background: #fff;
    }
  }
  .dashboard-mobile-sidebar-header {
    display: none;
  }
  @media (max-width: 786px) {
    .dashboard-content {
      padding: 40px;
      padding-top: 0;
    }
    @media (max-width: 500px) {
      .dashboard-content {
        padding: 20px;
        padding-top: 0;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      .dashboard-content {
        padding: 10px;
        padding-top: 0;
      }
    }
    .dashboard-mobile-sidebar-header {
      background-color: #fdd95f;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    .dashboard-mobile-siderbar-header-logo-title {
      display: flex;
      align-items: center;
    }
  }
`
