import React, { useContext, useEffect } from "react"
import Router from "next/router"
import { Layout as AntLayout } from "antd"
import styled from "styled-components"
import { AuthContext } from "./utils/AuthContext"
import { Layout } from "./layout"
import { Header } from "./components/molecules/Header"
import { Footer } from "./components/molecules/Footer"
import { NavComponent, Notice } from "./components"
import { t } from "i18next"
import { UnderMaintenanceCard } from "@project/shared"

const { Content } = AntLayout

const StyledMaintenanceCard = styled(UnderMaintenanceCard)`
  margin: 40px;
  @media (max-width: 767px) {
    margin: 20px;
  }
`

const PrivateRoute = (AuthComponent, additionalProps: any = {}) => {
  const { title, showNavBar = true, navColor } = additionalProps
  function PrivateComponent({ children }) {
    const { authenticated, loading, user } = useContext(AuthContext)

    useEffect(() => {
      const { pathname } = Router
      if (!loading) {
        if (!authenticated) {
          Router.push("/login")
          return
        }
        if (authenticated && pathname === "/") {
          Router.push("/")
        }
      }
    }, [authenticated, loading, user])

    return <>{authenticated && <>{children}</>}</>
  }

  type HigherState = { collapsed: boolean }
  return class Higher extends React.Component<
    Record<string, unknown>,
    HigherState
  > {
    constructor(props) {
      super(props)
      this.state = {
        collapsed:
          typeof window !== "undefined"
            ? localStorage.getItem("collapse_menu") === "true"
            : false,
      }
    }

    handleCollapse = () => {
      localStorage.setItem(
        "collapse_menu",
        this.state.collapsed ? "false" : "true"
      )
      this.setState((state) => ({
        collapsed: !state.collapsed,
      }))
    }

    handleSetCollapsed = () => {
      this.setState((state) => ({
        ...state,
        collapsed: true,
      }))
    }

    render() {
      return (
        <PrivateComponent>
          <Layout className={"dashboard"}>
            <Header navColor={navColor} />
            <Content className={"dashboard-content"}>
              {process.env.NEXT_PUBLIC_MAINTENANCE_MODE_CHILD === "1" ? (
                <StyledMaintenanceCard />
              ) : (
                <div className={"site-layout-background"}>
                  {showNavBar ? (
                    <>
                      <NavComponent
                        title={title || t("Child's state detail")}
                      />{" "}
                      <Notice />
                    </>
                  ) : (
                    ""
                  )}

                  <AuthComponent {...this.props} />
                </div>
              )}
            </Content>
            <Footer />
          </Layout>
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
